<template>
  <en-drawer :model-value="modelValue" width="80%" title="批量设置收费类别" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="收费类别" prop="chargingMethod.code">
        <select-maintain
          v-model="form.data.chargingMethod"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['CHGMTD']),
            convert: (data) => data.filter((item) => item.forServiceMaintenance)
          }"
          :props="{ label: 'message', value: '', disabled: (option: any) => option.code === 'RWK' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: EnocloudCommonDefinitions['LookupDto']) => typeof value === 'object'
  },

  config: {
    children: {
      form: {
        data: {
          chargingMethod: { code: '', message: '', type: '', description: '' }
        },
        rules: {
          'chargingMethod.code': [{ required: true, message: '请选择收费类别' }]
        }
      },

      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.refs.form.validate()
              this.emit('confirm', this.form.data.chargingMethod)
              this.footer.cancel.click()
            }
          }
        }
      }
    }
  }
})
</script>
